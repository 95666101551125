import React from "react";
import BlogTeaser from "../../components/blog-teaser";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useTheme, Theme, Container, Grid, Box, Typography, Button } from "@material-ui/core";
import Hero from "../../components/hero";
import Section from "../../components/section";
import ContentBlock from "../../components/contentBlock";
import { ShapeDown, ShapeStack, ShapeArea, ClipPath } from "../../components/shapes";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { RoundPaper } from "../../components/styledComponents";
import SEO from "../../components/seo";
import MainLayout from "../../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const ShapedImage = styled(Img)`
clip-path: url(#what-clip)
`;

const ShapeContain = styled(Grid)`
overflow: hidden;
`

const Number = styled(Typography)`
padding-right: 16px;
`;

const PaddedBox = styled(Box)((props: { theme: Theme }) => `
${props.theme.breakpoints.up("sm")} {
    padding: 64px 32px;
    margin-bottom: 72px;
}
`);

const RoundImage = styled(Img)`
border-radius: 16px 16px 0 0;
`;

const ProcessPaper = styled(RoundPaper)((props: {i: number, theme: Theme}) =>`
    background-color:${props.theme.palette.secondary.light};
    color: ${props.theme.palette.secondary.contrastText};
    ${props.theme.breakpoints.up("sm")} {
         margin-left: ${(props.i) * 24}px; max-width: 400px; 
    }
`);

const NeuroAcademyPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
        query {
            seo: neuroAcademyJson(block: {eq: "seo" }) {
                title
                description
                image {
                    childImageSharp {
                        resize(width: 750, height: 300, cropFocus: ENTROPY) {
                            src
                            width
                            height
                        }
                    }
                }
            }
            hero: neuroAcademyJson(block: {eq: "hero"}) {
                title
                tagline
                ctaText
                ctaUrl
                cta2Text
                cta2Url
                image {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 4160, maxHeight: 3120) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            what: neuroAcademyJson(block: {eq: "what" }) {
                title
                content
                logoImage {
                    childImageSharp {
                        fluid(maxWidth: 375, maxHeight:150, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                designImage {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            how: neuroAcademyJson(block: {eq: "how" }) {
                title
                content
                process {
                    text
                }
            }
            path: neuroAcademyJson(block: {eq: "path" }) {
                choices {
                    cta
                    ctaUrl
                    title
                    description
                    choiceImage {
                        childImageSharp {
                            fluid(maxWidth: 615, maxHeight: 300, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <MainLayout transparentNav>
            <SEO {...data.seo} location={props.location} />
            <Hero {...data.hero} overlayColor={theme.palette.primary.dark} heroHeight={85} />
            <Section tightBottom>
                <Container>
                    <Grid alignItems="center" container>
                        <Grid item xs={12} md={6}>
                            <ContentBlock maxWidth={375} {...data.what} />
                        </Grid>
                        <ShapeContain item xs={12} md={6}>
                            <ShapeArea>
                                <ShapeStack>
                                    <ShapeDown color={theme.palette.primary.dark} opacity={30} rotate={75} translateY={60} />
                                    <ShapeDown color={theme.palette.primary.light} opacity={75} scale={0.95} rotate={25} translateX={-15} translateY={60} />
                                </ShapeStack>
                                <ClipPath name="what-clip" />
                                <ShapedImage fluid={data.what.designImage.childImageSharp.fluid} />
                            </ShapeArea>
                        </ShapeContain>
                    </Grid>
                </Container>
            </Section>

            <Section tightTop>
                <Container maxWidth="md">
                    <Grid alignItems="center" container>
                        <Grid item xs={12} >
                            <ContentBlock {...data.how} />
                        </Grid>
                        <ShapeContain item xs={12}>
                            <Container maxWidth="sm">
                                <ShapeArea>
                                    <ShapeStack zIndex={-1}>
                                        <ShapeDown color={theme.palette.secondary.main} rotate={-18} opacity={55} />
                                        <ShapeDown color={theme.palette.primary.light} rotate={-80} opacity={50} translateX={50} translateY={100} />
                                    </ShapeStack>
                                </ShapeArea>
                                <PaddedBox theme={theme}>
                                    <Grid container spacing={4}>
                                        {data.how.process.map((x, i) => {
                                            return (<Grid item xs={12} key={i}>
                                                <ProcessPaper i={i} theme={theme} key={i}>
                                                    <Box display="flex" p={2} alignItems="center">
                                                        <Number variant="h4">{i + 1}</Number>
                                                        <Typography variant="h6" component="span">{x.text}</Typography>
                                                    </Box>
                                                </ProcessPaper>
                                            </Grid>);
                                        })}
                                    </Grid>
                                </PaddedBox>
                            </Container>
                        </ShapeContain>
                    </Grid>
                </Container>
            </Section>

            <Section id="packages" bgcolor={theme.palette.primary.dark} >
                <Container>
                    <Grid container spacing={4}>
                        {data.path.choices.map((c, i) => {
                            return (<Grid item xs={12} md={6} key={i}>
                                <RoundPaper>
                                    <RoundImage fluid={c.choiceImage.childImageSharp.fluid} />
                                    <Box py={3} px={5}>
                                        <Typography variant="h4" align="center" component="h3" gutterBottom={true}>{c.title}</Typography>
                                        <Typography variant="body1" component="span" gutterBottom={true}>{c.description}</Typography>
                                        <Box display="flex" justifyContent="center" pt={3}>
                                            <Button color="secondary" variant="contained" size="large"
                                                component={Link} to={c.ctaUrl} >
                                                {c.cta}
                                            </Button>
                                        </Box>
                                    </Box>
                                </RoundPaper>
                            </Grid>);
                        })}
                    </Grid>
                </Container>
            </Section>

            <BlogTeaser />
        </MainLayout>
    )
}

export default NeuroAcademyPage